
import { PropType } from "vue";
import { Vue, Options } from "vue-class-component";

@Options({
  components: {},
  props: {
    onClose: {
      default: Function as PropType<() => void>,
      type: Function
    },
    onSubmit: {
      default: Function as PropType<() => void>,
      type: Function
    },
    isCancelButton: {
      default: false,
      type: Boolean
    },
    isLoading: {
      default: false,
      type: Boolean
    },
    title: {
      default: "Renew Rates",
      type: String
    },
    isScroll: {
      default: true,
      type: Boolean
    },
    buttonTitle: {
      default: "Simpan",
      type: String
    },
    buttonCancelTitle: {
      default: "Batal",
      type: String
    },
    isDisabled: {
      default: false,
      type: Boolean
    },
    isIcon: {
      default: false,
      type: Boolean
    },
    isHeader: {
      default: true,
      type: Boolean
    },
    isFooter: {
      default: true,
      type: Boolean
    },
    icons: {
      default: "",
      type: String
    },
    modalWidth: {
      default: "w-5/12",
      type: String
    },
    customRound: {
      default: "",
      type: String
    },
    isBackgroundFooter: {
      default: false,
      type: Boolean
    }
  }
})
export default class extends Vue {}
