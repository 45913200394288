import { container } from "tsyringe";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { PosEntities, PosParam } from "@/domain/entities/Pos";
import { Pagination } from "@/domain/entities/Pagination";
import { PosPresenter } from "../presenters/PosPresenter";
import { RequestListPartner } from "@/data/payload/api/PartnerApiRequest";

export interface PosState {
  isLoading: boolean;
  isError: boolean;
  errorCause: string;
}

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "partner_management_pos"
})
class PosStore extends VuexModule implements PosState {
  isLoading = false;
  isError = false;
  errorCause = "";
  posList = new PosEntities(new Pagination(0, 10), []);
  search = "";
  pagination = new Pagination(1, 10);
  public firstRequest = true;
  public advancedFilter = {
    statusContract: "",
    statusPartner: "",
    posType: "",
    posParentId: 0,
    originCity: {
      name: "",
      code: ""
    }
  };

  @Action
  getPosList(params: RequestListPartner) {
    this.setLoading(true);
    const presenter = container.resolve(PosPresenter);
    return presenter
      .getListPos(params)
      .then(res => {
        this.setPosList(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch(err => {
        this.setError(true);
        this.setErrorCause(err.response ? "server" : "internet");
        this.setPosList(new PosEntities(new Pagination(0, 0), []));
      })
      .finally(() => this.setLoading(false));
  }

  @Action
  initPosList(params: PosParam) {
    this.setSearch("");
    this.setFirstRequest(true);
    this.initPagination();
    this.getPosList(
      new RequestListPartner({
        page: this.pagination.page,
        limit: this.pagination.limit,
        type: "pos",
        useLocation: true,
        useDefaultLocation: true,
        isWithPosBalance: true,
        posParentId: params.posParentId,
        posType: params.posParentId ? "all-child" : ""
      })
    );
  }

  @Action
  fetchPostList(params: PosParam) {
    this.setFirstRequest(false);
    this.getPosList(
      new RequestListPartner({
        page: this.pagination.page,
        limit: this.pagination.limit,
        search: this.search,
        contractStatus: this.advancedFilter.statusContract,
        cityCode: this.advancedFilter.originCity.code || "",
        status: this.advancedFilter.statusPartner,
        useLocation: true,
        type: "pos",
        useDefaultLocation: true,
        isWithPosBalance: true,
        posType: params.posParentId ? "all-child" : this.advancedFilter.posType,
        posParentId: params.posParentId
      })
    );
  }

  @Mutation
  initPagination() {
    this.pagination.page = 1;
    this.pagination.limit = 10;
  }

  @Mutation
  setPosList(val: PosEntities) {
    this.posList = val;
  }

  @Mutation
  setSearch(val: string) {
    this.search = val;
  }

  @Mutation
  setLoading(val: boolean) {
    this.isLoading = val;
  }

  @Mutation
  setError(val: boolean) {
    this.isError = val;
  }

  @Mutation
  setErrorCause(val: string) {
    this.errorCause = val;
  }

  @Mutation
  public setFirstRequest(value: boolean) {
    this.firstRequest = value;
  }

  @Mutation
  public setAdvancedFilter(value: any) {
    this.advancedFilter = value;
  }

  @Mutation
  public setDefaultPosList() {
    this.posList = new PosEntities(new Pagination(0, 0), []);
  }
}

export const PosController = getModule(PosStore);
