
/* eslint-disable @typescript-eslint/camelcase */
import {
  directDownloadFile,
  formatTimeCustom,
  getFormattedDate,
  reportTypeToTitle,
  sleep
} from "@/app/infrastructures/misc/Utils";
import { ReportController } from "@/app/ui/controllers/ReportController";
import { AccountController } from "@/app/ui/controllers/AccountController";
import groupBy from "lodash/groupBy";
import { Options, Vue } from "vue-class-component";
import Skeleton from "@/app/ui/components/skeleton/index.vue";
import formatDate from "@/app/infrastructures/misc/common-library/FormatDate";
@Options({
  components: {
    Skeleton
  }
})
export default class DownloadRequest extends Vue {
  get listDownloadReport() {
    return ReportController.listDownloadReport.data;
  }

  unmounted() {
    clearInterval(this.requestInInterval);
  }

  requestInInterval: any = null;

  initiateGetListDownloadReport() {
    ReportController.onGetListDownloadReport();
    this.requestInInterval = setInterval(
      () => {
        if (this.findWaitingAndToday()) {
          ReportController.onGetListDownloadReport();
        }
      },
      10000 // interval every 10 seconds
    );
  }

  findWaitingAndToday() {
    const checkWaitingAndToday = this.listDownloadReport.findIndex(
      (data: any) => {
        return (
          data.status === "waiting" &&
          new Date(data.createdAt).getDate() === new Date().getDate()
        );
      }
    );
    return checkWaitingAndToday > -1;
  }

  get isOpen() {
    return ReportController.isOpenDownloadRequest;
  }

  get isLoading() {
    return ReportController.isLoading;
  }

  onToggleMenu() {
    ReportController.setOpenDownloadRequest(!this.isOpen);
  }
  async onDownload(item: any) {
    const files = item.url.split(",");
    for (const file of files) {
      if (file) {
        directDownloadFile(file);
        await sleep(500);
      }
    }
  }
  numberOfFiles(item: any) {
    const files = item.url.split(",");
    return files.length ? `(${files.length})` : "";
  }

  mounted() {
    ReportController.listDownloadReport.pagination.limit = 20;
    this.initiateInfiniteScroll();
    this.initiateGetListDownloadReport();
  }

  initiateInfiniteScroll() {
    const getNewData = () => {
      ReportController.listDownloadReport.pagination.limit += 10;
      ReportController.onGetListDownloadReport();
    };
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          getNewData();
        }
      });
    }, {});

    const element: any = document.querySelector("#infinite-scroll");
    observer.observe(element);
  }

  get downloadRequestList() {
    const data = ReportController.listDownloadReport.data.map(item => {
      const date = formatDate(item.createdAt)?.split(",")[0];
      const time = formatDate(item.createdAt)?.split(",")[1];
     
      const addition = {
        title: `${time?.split(" ")[1]} - ${this.reportTitle(item)}`,
        dateString: date
      };
      return { ...item, ...addition };
    });
    const grouped = groupBy(data, "dateString");
    return Object.keys(grouped).map(item => grouped[item]);
  }

  reportTitle(item: any) {
    if (this.isForeignAccount) {
      return `${item.type.includes("dashboard") ? "" : ""} ${reportTypeToTitle[item.type] || item.type} ${this.$t('Laporan')}`;
    } else {
      return `${item.type.includes("dashboard") ? "" : this.$t('Laporan')} ${reportTypeToTitle[item.type] || item.type}`;
    }
    
  }

  get isForeignAccount() {
    return AccountController.accountData.accountIsForeign;
  }
}
