import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "space-y-2"
}
const _hoisted_2 = { class: "flex flex-row justify-between" }
const _hoisted_3 = {
  key: 1,
  class: "flex flex-col space-y-1"
}
const _hoisted_4 = { class: "text-12px text-gray-lp-800" }
const _hoisted_5 = { class: "font-bold text-12px xxl:text-14px text-black-lp-300" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_skeleton = _resolveComponent("skeleton")!

  return (_openBlock(), _createBlock("div", {
    class: "border-b-2 border-t border-l border-r border-gray-lp-400 rounded shadow px-4 pt-2 pb-6 cursor-pointer",
    style: `border-bottom-color: ${_ctx.color}`
  }, [
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock("div", _hoisted_1, [
          _createVNode(_component_skeleton, {
            width: "20.5rem",
            height: "1.8rem"
          }),
          _createVNode("div", _hoisted_2, [
            _createVNode(_component_skeleton, {
              width: "15.5rem",
              height: "2.2rem"
            }),
            _createVNode(_component_skeleton, {
              width: "2.5rem",
              height: "2.2rem"
            })
          ]),
          _createVNode(_component_skeleton, {
            width: "18.5rem",
            height: "1.4rem"
          })
        ]))
      : (_openBlock(), _createBlock("div", _hoisted_3, [
          _createVNode("div", _hoisted_4, _toDisplayString(_ctx.title), 1),
          _createVNode("div", _hoisted_5, _toDisplayString(_ctx.description), 1)
        ]))
  ], 4))
}