
import { Options, Vue } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import CardReport from "./component/card-report.vue";
import ModalDownload from "./component/modal-download.vue";
import DownloadRequest from "@/app/ui/components/widget-download-request/index.vue";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { ReportController } from "@/app/ui/controllers/ReportController";
import { FlagsPermissionReport } from "@/feature-flags/flag-report";

@Options({
  components: {
    DetailLayout,
    CardReport,
    ModalDownload,
    DownloadRequest
  }
})
export default class Report extends Vue {
  //   Search
  searchValue = "";
  _onGetSearch(value: string) {
    if (!value || value.length >= 3) {
      this.searchValue = value;
    }
  }

  onSearch(value: string) {
    this._onGetSearch(value);
  }
  onClearSearch() {
    this.searchValue = "";
    this._onGetSearch("");
  }

  // get profile account
  get dataProfile() {
    return AccountController.accountData;
  }

  // get account type
  get isInternal() {
    return this.dataProfile.isInternalAccount || this.dataProfile.isCustomerService;
  }
  get isClient() {
    return this.dataProfile.isAllClient;
  }
  get isPos() {
    return this.dataProfile.isPosAccount;
  }
  get isConsole() {
    return this.dataProfile.isConsoleAccount;
  }
  get isSubConsole() {
    return this.dataProfile.isSubConsoleAccount;
  }
  get isPosParent() {
    return this.dataProfile.isPosParentAccount;
  }
  get isPosBranch() {
    return this.dataProfile.isSubConsoleAccount;
  }

  created() {
    this.onGetReportList();
  }
  tmpReportListData: Array<any> = [];
  get reportListData() {
    if (this.searchValue && this.searchValue.length >= 3) {
      const filterData: Array<any> = [];

      for (const data of this.tmpReportListData) {
        const isExist = filterData.find(item => item.title === data.title);
        const res = data.detail.filter(
          (report: any) =>
            report.title
              .toLowerCase()
              .includes(this.searchValue.toLowerCase()) && report.permission
        );

        if (!isExist && res.length) {
          filterData.push({ ...data, detail: res, totalCard: res.length });
        }
      }

      return filterData;
    }

    return this.tmpReportListData;
  }

  get reportStt() {
    return [
      {
        title: this.$t('report.cards.titleSttReport'),
        description: "STT",
        color: "#B82025",
        permission: FlagsPermissionReport.permission_report_view_stt.isEnabled()
      },
      {
        title: "Laporan STT Client",
        description: "STT Client",
        color: "#0984e3",
        permission: FlagsPermissionReport.permission_report_view_stt_client.isEnabled()
      },
      {
        title: "Laporan STT Manual",
        description: "STT Manual",
        color: "#C8BBA7",
        permission: this.isInternal || this.isClient
      },
      {
        title: "Laporan Pembatalan STT",
        description: "Pembatalan STT",
        color: "#93E0F0",
        permission: (this.isInternal || this.isConsole) && !this.isForeignAccount
      },
      {
        title: "Laporan FPR",
        description: "FPR",
        color: "#1B48A0",
        permission: false
      },
      {
        title: "Laporan COD",
        description: "COD",
        color: "#BC9AFD",
        permission: false
      }
    ];

  }

  get reportFinance() {
    return [
      {
        title: "Laporan Top Up",
        description: "Top Up",
        color: "#93E0F0",
        permission: this.isInternal
      },
      {
        title: "Laporan Pendapatan",
        description: "Pendapatan",
        color: "#7ACB90",
        permission: false
      },
      {
        title: "Laporan Pembayaran",
        description: "Pembayaran",
        color: "#FF7474",
        permission: false
      },
      {
        title: "Laporan Transaksi",
        description: "Transaksi",
        color: "#FFCB01",
        permission: false
      },
      {
        title: "Laporan Referral POS",
        description: "Referral POS",
        color: "#6257BA",
        permission: this.isInternal
      },
      {
        title: "Laporan Bonus Komisi",
        description: "Bonus Komisi",
        color: "#51B6D5",
        permission: FlagsPermissionReport.report_bonus_komisi.isEnabled()
      },
      {
        title: "Laporan Komisi Affiliate",
        description: "Komisi Affiliate",
        color: "#7ACB90",
        permission: (this.isInternal || this.dataProfile.isPosParentAccount) && !this.isForeignAccount
      },
      {
        title: "Laporan Komisi POS Induk",
        description: "Komisi POS Induk",
        color: "#51B6D5",
        permission: (this.isInternal || this.isPosParent) && !this.isForeignAccount
      }
    ];

  }

  get reportDeliveryPartOne() {
    return [
      {
        title: "Laporan Transit",
        description: "Transit",
        color: "#F09035",
        permission: false
      },
      {
        title: "Laporan Misroute",
        description: "Misroute",
        color: "#2468F6",
        permission: (this.isInternal || this.isConsole ) && !this.isForeignAccount
      },
      {
        title: "Laporan Shortland",
        description: "Shortland",
        color: "#00875A",
        permission: (this.isInternal || this.isConsole) && !this.isForeignAccount
      },
      {
        title: "Laporan Pengiriman",
        description: "Pengiriman",
        color: "#BC9AFD",
        permission: (this.isInternal || this.isConsole) && !this.isForeignAccount
      }
    ]
  }

  get reportDeliveryPartTwo() {
    return [
      {
        title: "Laporan Scrap",
        description: "Scrap",
        color: "#C8BBA7",
        permission: (this.isInternal || this.isConsole) && !this.isForeignAccount
      },
      {
        title: "Laporan Pengiriman Keluar (Kota Asal)",
        description: "Pengiriman Keluar (Kota Asal)",
        color: "#FF7474",
        permission: (this.isInternal || this.isConsole) && !this.isForeignAccount
      },
      {
        title: "Laporan Pengiriman Masuk (Kota Tujuan)",
        description: "Pengiriman Masuk (Kota Tujuan)",
        color: "#FFCB01",
        permission: (this.isInternal || this.isConsole) && !this.isForeignAccount
      }
    ]
  }

  get reportDeliveryPartThree() {
    return [
      {
        title: "Laporan Komisi Pengiriman",
        description: "Komisi Pengiriman",
        color: "#F09035",
        permission:
          (this.isInternal ||
          this.isConsole ||
          this.isSubConsole ||
          this.dataProfile.isPosParentAccount) && !this.isForeignAccount
      },
      {
        title: "Laporan Komisi Pengiriman Tier X",
        description: "DTPOL Tier X",
        color: "#FF7474",
        permission: (this.isInternal || this.isConsole) && !this.isForeignAccount
      }
    ]
  }

  get reportDelivery() {
    return [
      ...this.reportDeliveryPartOne,
      ...this.reportDeliveryPartTwo,
      ...this.reportDeliveryPartThree
    ];
  }

  onGetReportList() {
    this.tmpReportListData = [
      {
        title: "STT",
        detail: this.reportStt,
        totalCard: this.reportStt.filter(card => card.permission).length
      },
      {
        title: "Finance",
        detail: this.reportFinance,
        totalCard: this.reportFinance.filter(card => card.permission).length
      },
      {
        title: "Delivery",
        detail: this.reportDelivery,
        totalCard: this.reportDelivery.filter(card => card.permission).length
      }
    ];
  }

  isModalDownload = false;
  detailData = null;
  onModalDownload(data: any) {
    this.detailData = data;
    this.isModalDownload = true;

    if (data.title === this.$t('report.cards.titleSttReport') && !ReportController.lastSyncHasClicked) {
      ReportController.getLastSyncSttReport();
    }
  }

  get isForeignAccount() {
    return AccountController.accountData.accountIsForeign;
  }
}
