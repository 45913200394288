
import { Vue, Options } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import Skeleton from "@/app/ui/components/skeleton/index.vue";

@Options({
  components: {
    Title,
    Skeleton
  },
  props: {
    title: {
      default: "",
      type: String
    },
    description: {
      default: "",
      type: String
    },
    color: {
      default: "",
      type: String
    },
    isLoading: {
      default: false,
      type: Boolean
    }
  }
})
export default class CardReport extends Vue {}
