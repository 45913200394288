
export const commissionClientPartnerCondition = (description: string) => {
    return description === "Komisi Pengiriman" ||
        description === "Referral POS" ||
        description === "Bonus Komisi" ||
        description === "Komisi Affiliate" ||
        description === "Komisi POS Induk"

}

export const showClientPartner = (description: string) => {
    return description === "Shortland" ||
        description === "Pembatalan STT" ||
        description === "Misroute" ||
        description === "Scrap" ||
        description.includes("Pengiriman") ||
        description === "Top Up" ||
        description === "DTPOL Tier X" ||
        commissionClientPartnerCondition(description);
}

export const sttPartnerCondition = (description: string) => {
    return description === "STT Manual" ||
        description === "Pembatalan STT" ||
        description === "STT" ||
        description === "STT Client" ||
        description.includes("Pengiriman");
}

export const showPartner = (description: string) => {
    return description === "Shortland" ||
        description === "Misroute" ||
        description === "Scrap" ||
        description === "Komisi Pengiriman" ||
        description === "DTPOL Tier X" ||
        description === "Referral POS" ||
        description === "Komisi POS Induk" || 
        sttPartnerCondition(description);
}

export const commissionReportType = (description: string) => {
    return description === "Komisi Pengiriman" ||
        description === "Referral POS" ||
        description === "Bonus Komisi" ||
        description === "Komisi Affiliate" ||
        description === "Komisi POS Induk" ||
        description.includes("Pengiriman");
}

export const showReportType = (description: string) => {
    return description === "Shortland" ||
      description === "STT Manual" ||
      description === "Pembatalan STT" ||
      description === "Misroute" ||
      description === "Scrap" ||
      description === "Top Up" ||
      description === "DTPOL Tier X" ||
      commissionReportType(description);
}

export const customConditionOrigincity = (description: string, isConsole: boolean, isInternal: boolean) => {
    return (description.includes("(Kota Tujuan)") && isConsole) ||
        (description === "Komisi Pengiriman" && !isInternal) ||
        (description === "DTPOL Tier X" && !isConsole);
}

export const showOriginCity = (description: string, isConsole: boolean, isInternal: boolean) => {
    return customConditionOrigincity(description, isConsole, isInternal) ||
        description === "STT Manual" ||
        description === "Top Up" ||
        description === "Referral POS" ||
        description === "Bonus Komisi" ||
        description === "Komisi Affiliate" ||
        description === "Komisi POS Induk"
}

export const clientPartnerTitle = (description: string, isPos: boolean, isConsole: boolean) => {
    return isConsole ||
        isPos ||
        description === "Top Up" ||
        description === "Bonus Komisi" ||
        description === "Komisi Affiliate" ||
        description === "Komisi POS Induk";
}

export const validationDate = (startDate: number, endDate: number, lastDateOfTheMonth: number) => {
    return startDate === 1 && endDate === lastDateOfTheMonth;
}

export const validationMonth = (startMonth: number, endMonth: number) => {
    return startMonth === endMonth;
}

export const validationYear = (startYear: number, endYear: number) => {
    return startYear === endYear;
}

export const validationMonthlySelected = (date: any) => {
    return validationDate(date.startDate, date.endDate, date.lastDateOfTheMonth) &&
        validationMonth(date.startMonth, date.endMonth) &&
        validationYear(date.startYear, date.endYear)
}

export const disableBasedOnDescription = (description: string) => {
    return description === "shortland" ||
        description === "pembatalan stt" ||
        description === "scrap";
}

export const commissionDateRangecondition = (description: string) => {
    return description === "Komisi Pengiriman" ||
        description === "DTPOL Tier X" ||
        description === "Referral POS" ||
        description === "Bonus Komisi" ||
        description === "Komisi Affiliate" ||
        description === "Komisi POS Induk";
}

export const sttDateRangeCondition = (description: string) => {
    return description === "STT" ||
        description === "STT Client" ||
        description === "STT Manual" || 
        description === "Pengiriman";
}

export const usingDateRange = (description: string) => {
    return sttDateRangeCondition(description) ||
        description === "Misroute" ||
        description === "Shortland" ||
        description === "Scrap" ||
        description === "Pengiriman Keluar (Kota Asal)" ||
        description === "Pengiriman Masuk (Kota Tujuan)" ||
        description === "Pembatalan STT" ||
        commissionDateRangecondition(description);
}